function Loader() {
  // return (
  //   <div className="lds-center">
  //     <div className="lds-dual-ring"></div>
  //   </div>
  // );
  return null;
}

export default Loader;
