import ls from 'localstorage-slim';
import { AES, enc } from 'crypto-js';
import { REACT_APP_CRYPTO_SECRET } from 'constants/config';

//global ls encryption setup
ls.config.encrypt = true;
ls.config.secret = 7369859707561204;

//encrypt data
const encryptData = (data) => {
  return AES.encrypt(JSON.stringify(data), REACT_APP_CRYPTO_SECRET).toString();
};

//decryptData
const decryptData = (data) => {
  if (data) {
    const bytes = AES.decrypt(data, REACT_APP_CRYPTO_SECRET);
    return JSON.parse(bytes.toString(enc.Utf8));
  }
  return data;
};

export { ls, encryptData, decryptData };
