import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [] };

export const ItemsSlice = createSlice({
  name: 'itemsData',
  initialState,
  reducers: {
    storeItemsData: (state, action) => {
      state.data = action.payload;
    },
    addItemsData: (state, action) => {
      const { id, name, thumbnail, checked } = action.payload;
      if (checked) {
        state.data.push({ id, name, thumbnail, quantity: 1 });
      } else {
        state.data = state.data.filter((item) => item.id !== id);
      }
    },
    flushItemsData: () => initialState,
    updateValue: (state, action) => {
      const { id, quantity } = action.payload;
      const item = state.data.find((item) => item.id === id);
      if (item) {
        item.quantity = quantity;
      }
    },
    deleteItemsData: (state, action) => {
      const id = action.payload;
      state.data = state.data.filter((item) => item.id !== id);
    },
  },
});

export const { storeItemsData, addItemsData, flushItemsData, updateValue, deleteItemsData } = ItemsSlice.actions;
export default ItemsSlice.reducer;
