import { useEffect } from 'react';
import { Sidebar } from '.';
import { setActiveWrapperStatus } from 'reducers/sidebarSlice';
import { useDispatch } from 'react-redux';
import { school, label } from 'constants/icons';
import { useParams } from 'react-router-dom';

export const SchoolSidebar = ({ displayVoucher = false }) => {
  const { role } = useParams();
  const { schoolId } = useParams();
  const dispatch = useDispatch();
  const items = [
    { id: 1, name: 'items', label: 'Items', link: `/${role}/school/${schoolId}/items`, icon: school },
    { id: 2, name: 'classes', label: 'Class', link: `/${role}/school/${schoolId}/classes`, icon: school },
    { id: 3, name: 'packages', label: 'Package', link: `/${role}/school/${schoolId}/packages`, icon: school },
    { id: 4, name: 'order-logs', label: 'Order', link: `/${role}/school/${schoolId}/order-logs`, icon: school },
    { id: 7, name: 'reporting', label: 'Reporting', link: `/${role}/school/${schoolId}/reporting`, icon: school },
  ];

  if (role === 'super_admin') {
    items.push({ id: 5, name: 'rearrange-packages', label: 'Rearrange Package', link: `/${role}/school/${schoolId}/rearrange-packages`, icon: school });
  }

  if (displayVoucher) {
    items.push({ id: 6, name: 'voucher-code', label: 'Voucher Code', link: `/${role}/school/${schoolId}/voucher-code`, icon: label });
  }
  const back = {
    link: `/${role}/schools`,
    name: 'schools',
    label: 'Schools',
  };
  useEffect(() => {
    dispatch(setActiveWrapperStatus(true));
  }, []);
  return <Sidebar items={items} back={back} />;
};
