import { openDB } from 'idb';

export const openIdb = (dbName, storeName, dbVersion) => {
  const dbPromise = openDB(dbName, dbVersion, {
    upgrade(db) {
      db.createObjectStore(storeName);
    },
  });

  return {
    get: async function (key) {
      return (await dbPromise).get(storeName, key);
    },
    set: async function (key, val) {
      return (await dbPromise).put(storeName, val, key);
    },
    del: async function (key) {
      return (await dbPromise).delete(storeName, key);
    },
    clear: async function () {
      return (await dbPromise).clear(storeName);
    },
    keys: async function () {
      return (await dbPromise).getAllKeys(storeName);
    },
  };
};
