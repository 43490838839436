import { openIdb } from './idb';
const { get, set } = openIdb('api_cache_db', 'cache', 1);
const CACHE_TIME = parseInt(process.env.REACT_APP_API_CACHE_TIME, 10) * 1000 || 10000;

/**
 * Custom data type defining parameters for a cached request.
 * @typedef {Object} CachedRequestParams
 * @property {string} key - Unique Cache key
 * @property {Function} fn - Function for API request
 * @property {Array} params - Array containing parameters for the API request function
 * @property {number} cacheTime - API cache time in seconds
 */

/**
 * function to  cache API requests
 * @param {CachedRequestParams} params - Parameters for a cached request
 * @returns {Promise} - API Response or error
 */
export default async function cachedRequest({ key, fn, params, cacheTime }) {
  try {
    const valid = typeof key === 'string' && key.trim() && typeof fn === 'function' && Array.isArray(params);
    if (!valid) {
      throw new Error('Invalid parameters.');
    }

    const intCacheTime = parseInt(cacheTime, 10) * 1000;
    const API_CACHE_TIME = Number.isNaN(intCacheTime) ? CACHE_TIME : intCacheTime;

    let cacheKey = `${key}_${window.btoa(JSON.stringify(params))}`;
    try {
      const cachedResponse = await get(cacheKey);
      // console.log(cachedResponse)
      if (cachedResponse && Date.now() - cachedResponse.timestamp < API_CACHE_TIME) {
        return cachedResponse;
      }
    } catch (e) {
      console.log(e);
    }

    const serverResponse = await fn.apply(null, params);
    // console.log(serverResponse)
    try {
      if (serverResponse) {
        const { data, status, statusText, headers } = serverResponse;
        const timestamp = Date.now();
        if (data instanceof Object) {
          await set(cacheKey, { data, status, statusText, headers, timestamp });
        }
      }
    } catch (e) {
      console.log(e);
    }
    return serverResponse;
  } catch (e) {
    return Promise.reject(e);
  }
}
