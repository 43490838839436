import { Link, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setActiveWrapperStatus } from 'reducers/sidebarSlice';
import { useEffect, useState } from 'react';
import { leftArrow } from 'constants/icons';
export const Sidebar = ({ items, back }) => {
  const { parentId, orderId, schoolId, packageId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const defaultPath = back?.link ? back.link : '';
  const [backlink, setBackLink] = useState(defaultPath);

  const getBack = () => {
    if (location.pathname === defaultPath) {
      dispatch(setActiveWrapperStatus(false));
    } else {
      setBackLink(defaultPath);
      dispatch(setActiveWrapperStatus(false));
    }
  };
  const navList = [...items];
  const isMyPage = (searchTxt, path) => {
    if (parentId && orderId) {
      if (searchTxt === 'order-logs') {
        return true;
      }
      return false;
    } else if (schoolId && packageId) {
      if (searchTxt === 'packages') {
        return true;
      }
      return false;
    } else {
      const patterns = path.split('/');
      return patterns[patterns.length - 1] === searchTxt;

      // let pathReg = new RegExp(searchTxt);
      // return pathReg.test(path);
    }
  };
  // useEffect(() => {
  //   console.log(location);
  // }, [location]);
  return (
    <div className="dynamic-inner-sidebar">
      {back && (
        <div className="fixed-sidebar-back">
          <Link className="sidebar-back-nav" to={backlink} onClick={getBack}>
            {leftArrow}
            <span className="text-truncate" title={back?.label ? back.label : 'Back'}>
              {back?.label ? back.label : 'Back'}
            </span>
          </Link>
          <span className="divider"></span>
        </div>
      )}

      <ul className="sidebar-list">
        {navList &&
          navList.length > 0 &&
          navList.map((nav) => (
            <li key={nav.id} className={isMyPage(nav.name, location.pathname) ? 'active' : ''}>
              <Link to={nav.link}>
                <span className="text-truncate">{nav.label} </span>
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};
