import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  csrfToken: '',
  user: {},
  store_sub_domain: '',
  loading: true,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      for (let data in action.payload) {
        if (data in state) {
          state[data] = action.payload[data];
        }
      }
    },

    resetUserData: (state, action) => {
      return { ...initialState, store_sub_domain: state.store_sub_domain };
    },
    setUserLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserData, resetUserData, setUserLoading } = userSlice.actions;

export default userSlice.reducer;
