import React from 'react';
import { FloatingLabel } from 'react-myoneui';
import { renderLabel } from 'utils/renderLabel';

function CustomFloatingLabel({ label, children, ...rest }) {
  return (
    <FloatingLabel label={renderLabel(label)} {...rest}>
      {children}
    </FloatingLabel>
  );
}

export default CustomFloatingLabel;
