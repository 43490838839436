import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { RequireAdminAuth, RequireParentAuth, UnProtectedRoute } from 'components/RequireAuth';
import SchoolEmails from 'modules/Admin/schoolEmails';
import Loader from 'components/Loader';

const OrderProfileLayout = lazy(() => import('modules/schoolManagement/dashboard/OrderProfileLayout'));
const MainLayout = lazy(() => import('layouts/MainLayout'));
const CreatePassword = lazy(() => import('modules/createPassword'));
const UserManagement = lazy(() => import('modules/userManagement'));
const Overview = lazy(() => import('modules/overview'));
const Profile = lazy(() => import('modules/overview/profile'));
const Error404 = lazy(() => import('modules/Error404'));
const Settings = lazy(() => import('modules/settings'));
const MySubscription = lazy(() => import('modules/subscription/mysubscription'));
const Organization = lazy(() => import('modules/organization'));
const AccessManagement = lazy(() => import('modules/accessManagement'));

const Portfolio = lazy(() => import('modules/documentManager/Portfolio'));
const ProductDetail = lazy(() => import('modules/schoolManagement/productDetail'));
const Bundle = lazy(() => import('modules/schoolManagement/bundle'));
const Billing = lazy(() => import('modules/schoolManagement/billing'));
const SchoolLayout = lazy(() => import('layouts/SchoolLayout'));
const School = lazy(() => import('modules/Admin/school'));
const SchoolClass = lazy(() => import('modules/Admin/schoolClass'));
const SchoolItems = lazy(() => import('modules/Admin/schoolItems'));
const SchoolVoucherCode = lazy(() => import('modules/Admin/schoolVoucherCode'));
const SchoolReporting = lazy(() => import('modules/Admin/reporting'));
const SchoolPackage = lazy(() => import('modules/Admin/schoolPackage'));
const SchoolParents = lazy(() => import('modules/Admin/schoolParents'));
const SchoolLogs = lazy(() => import('modules/Admin/schoolLogs'));
const SchoolOrderLogs = lazy(() => import('modules/Admin/schoolOrderLogs'));

const ParentHome = lazy(() => import('modules/schoolManagement/home/parent'));
const PackageManage = lazy(() => import('modules/Admin/schoolManage'));
const SchoolSuppliers = lazy(() => import('modules/Admin/schoolSuplliers'));
const PackageList = lazy(() => import('modules/schoolManagement/packageList'));
const PackageDetail = lazy(() => import('modules/schoolManagement/packageDetail'));
const PackageItem = lazy(() => import('modules/schoolManagement/packageItem'));
const NewPackage = lazy(() => import('modules/schoolManagement/packageItem/newPackage'));
const OrderDetail = lazy(() => import('modules/schoolManagement/orderDetail'));
const OrderLogs = lazy(() => import('modules/Admin/orderLogs'));
const StripeLogs = lazy(() => import('modules/Admin/stripeLogs'));
const ForgotPassword = lazy(() => import('modules/createPassword/forgotPassword'));
const Home = lazy(() => import('modules/schoolManagement/home'));
const SchoolStudents = lazy(() => import('modules/Admin/schoolStudents'));
const Orders = lazy(() => import('modules/schoolManagement/dashboard'));
const Students = lazy(() => import('modules/schoolManagement/dashboard/students'));
const Schools = lazy(() => import('modules/schoolManagement/dashboard/schools'));
const Profiles = lazy(() => import('modules/schoolManagement/dashboard/profile'));
const Users = lazy(() => import('modules/Admin/users'));
const AdminProfiles = lazy(() => import('modules/Admin/profile'));
const EmailLogs = lazy(() => import('modules/Admin/emailLogs'));
const AdminFaqOld = lazy(() => import('modules/Admin/faq/AdminFaqOld'));
const AdminFaq = lazy(() => import('modules/Admin/faq'));

const FAQ = lazy(() => import('modules/faq'));
const CaseAttachRate = lazy(() => import('modules/Admin/caseAttachRate'));

const Reports = lazy(() => import('modules/reports'));
const Dashboard = lazy(() => import('modules/dashboard'));
const Security = lazy(() => import('modules/security'));
const Setting = lazy(() => import('modules/schoolManagement/dashboard/setting'));
const PaymentPlan = lazy(() => import('modules/schoolManagement/dashboard/paymentPlan'));
const SchoolRearrangePackages = lazy(() => import('modules/Admin/schoolRearrangePackages/index'));
const SchoolOrderQuotation = lazy(() => import('modules/Admin/schoolOrderQuotation'));
const ParentFaq = lazy(() => import('modules/parentFaq'));

const basename = process.env.REACT_APP_BASENAME || '';

export default (
  <Router basename={basename}>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<RequireAdminAuth />}>
          <Route path=":role" element={<MainLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="schools/*" element={<School />} />
            <Route path="school-class/*" element={<SchoolClass />} />
            <Route path="school/:schoolId/classes/*" element={<SchoolClass />} />
            <Route path="school-items/*" element={<SchoolItems />} />
            <Route path="school/:schoolId/items/*" element={<SchoolItems />} />
            <Route path="school-package/*" element={<SchoolPackage />} />
            <Route path="school/:schoolId/packages/*" element={<SchoolPackage />} />
            <Route path="school/:schoolId/rearrange-packages" element={<SchoolRearrangePackages />} />
            <Route path="school-parents/*" element={<SchoolParents />} />
            <Route path="school-students/*" element={<SchoolStudents />} />
            <Route path="school/:schoolId/students/*" element={<SchoolStudents />} />
            <Route path="parent/:parentId/students/*" element={<SchoolStudents />} />
            <Route path="order/:orderId/students/*" element={<SchoolStudents />} />
            <Route path="parent/:parentId/order/:orderId/students/" element={<SchoolStudents />} />
            <Route path="school/:schoolId/order/:orderId/students/" element={<SchoolStudents />} />
            <Route path="school-suppliers/*" element={<SchoolSuppliers />} />
            <Route path="logs/school-logs/*" element={<SchoolLogs />} />
            <Route path="logs/order-logs/*" element={<SchoolOrderLogs />} />
            <Route path="logs/stripe-logs/*" element={<StripeLogs />} />
            <Route path="school/:schoolId/package/:packageId/manage" element={<PackageManage page="school-package-manage" />} />
            <Route path="school-package-manage/:schoolId/:packageId" element={<PackageManage />} />
            <Route path="order-logs/*" element={<OrderLogs />} />
            <Route path="school/:schoolId/order-logs/*" element={<OrderLogs />} />
            <Route path="school/:schoolId/order-logs/*" element={<OrderLogs />} />
            <Route path="parent/:parentId/order-logs/*" element={<OrderLogs />} />
            <Route path="school/:schoolId/reporting/*" element={<SchoolReporting />} />
            <Route path="school/:schoolId/voucher-code/" element={<SchoolVoucherCode />} />
            <Route path="users/*" element={<Users />} />
            <Route path="reports/*" element={<Reports />} />
            <Route path="security/*" element={<Security />} />
            <Route path="overview/*" element={<Overview />} />
            <Route path="overview/profile/*" element={<Profile />} />
            <Route path="user-management/*" element={<UserManagement />} />
            <Route path="settings/*" element={<Settings />} />
            <Route path="my-subscription/*" element={<MySubscription />} />
            <Route path="organization/*" element={<Organization />} />
            <Route path="access-management/*" element={<AccessManagement />} />
            <Route path="library/portfolio/*" element={<Portfolio />} />
            <Route path="profile" element={<AdminProfiles />} />
            <Route path="emails" element={<EmailLogs />} />
            <Route path="case-attach-rate" element={<CaseAttachRate />} />
            <Route path="school/:schoolId/active-email-template/*" element={<SchoolEmails />} />
            <Route path="school-order-quotation/*" element={<SchoolOrderQuotation />} />
            <Route path="faq/*" element={<AdminFaq />} />
            <Route path="faq-old/*" element={<AdminFaqOld />} />

            {/* school admin additional route for voucher code */}
            <Route path="voucher-code/*" element={<SchoolVoucherCode />} />
          </Route>
        </Route>

        <Route element={<RequireParentAuth />}>
          <Route path="parent" element={<SchoolLayout />}>
            <Route index element={<Home />} />
            <Route path="school/:schoolCode/package-list" element={<PackageList />} />
            <Route path="school/:schoolCode/package/:packageId" element={<PackageItem />} />
            <Route path="school/:schoolCode/package-item/:packageId" element={<NewPackage />} />
            <Route path="dashboard" element={<ParentHome />} />
            <Route path="school/:schoolId/bundle" element={<Bundle />} />
            <Route path="school/:schoolId/bundle/:packageId/product-detail" element={<ProductDetail />} />
            <Route path="billing-address" element={<Billing />} />
            <Route path="order-details/*" element={<OrderDetail />} />
            <Route path="profile" element={<Profiles />} />
            <Route element={<OrderProfileLayout />}>
              <Route path="order-detail" element={<Orders />} />
              <Route path="students" element={<Students />} />
              <Route path="schools" element={<Schools />} />
              {/* <Route path="installment" element={<Setting />} /> */}
              <Route path="installment" element={<PaymentPlan />} />
              <Route path="faq" element={<ParentFaq />} />
            </Route>
          </Route>
        </Route>

        <Route element={<UnProtectedRoute />}>
          <Route path="/" element={<SchoolLayout />}>
            <Route index element={<Home />} />
            <Route path="/school/:schoolId/bundle" element={<Bundle />} />
            <Route path="/school/:schoolId/bundle/:packageId/product-detail" element={<ProductDetail />} />
            <Route path="/billing-address" element={<Billing />} />
            <Route path="/school/:schoolCode/package-list" element={<PackageList />} />
            <Route path="/package-detail" element={<PackageDetail />} />
            <Route path="/school/:schoolCode/package/:packageId" element={<PackageItem />} />
            <Route path="/school/:schoolCode/package-item/:packageId" element={<NewPackage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<CreatePassword />} />
            {/* <Route path="/faq" element={<FAQ />} /> */}

            {/* <Route path="/error403" element={<Error403 />} /> */}

            {/* <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} /> */}

            {/* <Route path="/additional-details" element={<AdditionalDetails />} /> */}
            {/* <Route path="/error404" element={<Error404 />} /> */}
            {/* <Route path="/error403-nav" element={<Error403withNav />} /> */}
            {/* <Route path="/subscription-trial" element={<SubscriptionTrialForm />} /> */}
            {/* <Route path="library/document-manager/*" element={<DocumentManager />} /> */}
            {/* <Route path="/order-detail/*" element={<OrderDetail />} /> */}
          </Route>
        </Route>
        <Route path="/faq" element={<FAQ />} />

        {/* wildcard route */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  </Router>
);
