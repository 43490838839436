import { configureStore } from '@reduxjs/toolkit';
import adminReducer from 'reducers/adminSlice';
import sidebarReducer from 'reducers/sidebarSlice';
import userReducer from 'reducers/userSlice';
import searchReducer from 'reducers/searchSlice';
import itemsSlice from 'reducers/itemsSlice';

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    sidebar: sidebarReducer,
    user: userReducer,
    search: searchReducer,
    itemsSlice: itemsSlice,
  },
});
