import axios from 'utils/axios';

export const getInitialTranslations = (params) => {
  return axios.request({
    url: params.url,
    method: 'get',
  });
};

export const getTranslationsForModule = (params) => {
  return axios.request({
    url: `/${params.module}/language`,
    method: 'get',
    params: { lang: params.lang },
  });
};
