import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: '',
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchTxt: (state, action) => {
      state['search'] = action.payload;
    },
    resetSearchTxt: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSearchTxt, resetSearchTxt } = searchSlice.actions;

export default searchSlice.reducer;
