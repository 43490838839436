import { React, useState, forwardRef, Children, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SchoolSidebar } from 'components/sidebar/SchoolSidebar';
import { useSelector } from 'react-redux';
import { Card, Dropdown, Form, SecondaryHeader, SecondaryHeaderButtonWrap, SecondaryHeaderInfo, Spinner } from 'react-myoneui';
import Button from 'react-myoneui/lib/Button';
import CustomFloatingLabel from 'components/CustomFloatingLabel';
import { renderLabel } from 'utils/renderLabel';
import { addIcon, previewContentIcon, resetIcon } from 'constants/icons';
import SchoolEmailEditor from 'modules/Admin/schoolEmails/EmailTextEditor.js';
import { getPreviewContent, getSchoolEmail, setSchoolEmail } from 'api/admin/index.js';
import EmailPreview from 'modules/Admin/schoolEmails/EmailPreview.js';

function SchoolEmails() {
  const { schoolId } = useParams();
  const [schoolInfo, setSchoolInfo] = useState(null);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState({});
  const [email, setEmail] = useState({ subject: '', introduction: '', signature: '' });
  const [introSelectedData, setIntroSelectedData] = useState('');
  const [resetIntro, setResetIntro] = useState(false);
  const [sigSelectedData, setSigSelectedData] = useState('');
  const [resetSig, setResetSig] = useState(false);
  const [previewEmail, setPreviewEmail] = useState(false);
  const [previewData, setPreviewData] = useState('');

  const {
    user: {
      role: { privileges },
    },
  } = useSelector((state) => state.user);

  useEffect(() => {
    async function fetchSchoolEmail() {
      try {
        setFetchLoading(true);
        const { data } = await getSchoolEmail({ school: schoolId });
        if (data?.success) {
          const { subject, introduction, signature } = data.data.schoolEmail;
          setEmail({
            subject,
            introduction,
            signature,
          });
          setSchoolInfo(data.data.school);
          setDefaultEmail(data.data.defaultEmail);
        }
      } catch (err) {
        console.log('Err >> ', err);
      } finally {
        setFetchLoading(false);
      }
    }
    fetchSchoolEmail();
  }, []);

  const handleChange = (value, name) => {
    setEmail({ ...email, [name]: value });
  };

  const onSave = async () => {
    setSaveLoading(true);
    try {
      await setSchoolEmail({ ...email, school: schoolId });
    } catch (err) {
      console.log('Error >> ', err);
    } finally {
      setSaveLoading(false);
    }
  };

  const handlePreview = async () => {
    try {
      setPreviewLoading(true);
      const { data } = await getPreviewContent({ introduction: email.introduction, signature: email.signature });
      setPreviewData(data.data);
      setPreviewEmail(true);
    } catch (err) {
      console.log('Error >> ', err);
    } finally {
      setPreviewLoading(false);
    }
  };

  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <div
      role="button"
      tabIndex={0}
      ref={ref}
      className="btn"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

  const CustomMenu = forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <ul className="list-unstyled mb-0">{Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().startsWith(value))}</ul>
      </div>
    );
  });

  return (
    <>
      {!fetchLoading ? (
        <>
          {schoolId && <SchoolSidebar displayVoucher={schoolInfo?.enable_voucher_code} />}
          <div className="content-wrapper">
            <SecondaryHeader>
              <SecondaryHeaderInfo SecondaryHeaderTitle={schoolInfo?.name} />
              <SecondaryHeaderButtonWrap>
                <Button variant="text-icon" className="text-icon" onClick={() => handlePreview()}>
                  {previewLoading && <Spinner animation="border" size="sm" />}
                  Preview Email
                  {previewContentIcon}
                </Button>

                <Button variant="secondary" onClick={() => onSave()}>
                  {saveLoading && <Spinner animation="border" size="sm" />}
                  Save Changes
                </Button>
              </SecondaryHeaderButtonWrap>
            </SecondaryHeader>

            <div className="content-block">
              <h4 className="email-title mb-3">{renderLabel('Email Registration Subject*')}</h4>
              <CustomFloatingLabel controlId="subjectName" label="Email Registration Subject*" className="mb-4">
                <Form.Control type="text" placeholder="Email Registration Subject" required value={email.subject} name="subject" onChange={(e) => handleChange(e.target.value, 'subject')} />
                <Form.Control.Feedback type="invalid">Please provide Email Registration Subject.</Form.Control.Feedback>
              </CustomFloatingLabel>

              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="email-title">{renderLabel('Email Registration Introduction*')}</h4>
                <div className="reset-button-wrap">
                  <Button
                    variant="outline"
                    className="dark"
                    onClick={() => {
                      setEmail({ ...email, introduction: defaultEmail.introduction });
                      setIntroSelectedData('');
                      setResetIntro(true);
                    }}
                  >
                    {resetIcon} Reset
                  </Button>
                  <Dropdown className="dropdown-select position-static add-content-dropdown" align="end">
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                      <h4 className="program-title text-primary">{addIcon} Add Content</h4>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu} className="dropdown-menu mw-dropdown-default">
                      <Dropdown.Item eventKey="1" onClick={() => setIntroSelectedData('{{user_name}}')}>
                        <span title="User Name">User Name</span>
                      </Dropdown.Item>

                      <Dropdown.Item eventKey="2" onClick={() => setIntroSelectedData('{{user_email}}')}>
                        <span title="User Email">User Email</span>
                      </Dropdown.Item>

                      <Dropdown.Item eventKey="3" onClick={() => setIntroSelectedData('{{faq_link}}')}>
                        <span title="faq_link">FAQ Link</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <Card className="mb-3 react-text-editor">
                <SchoolEmailEditor
                  theme="snow"
                  name="introduction"
                  onChange={(e) => handleChange(e, 'introduction')}
                  value={email.introduction}
                  dynamicValue={introSelectedData}
                  resetDynamicValue={() => setIntroSelectedData('')}
                  reset={resetIntro}
                />
              </Card>

              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="email-title">{renderLabel('Email Registration Signature*')}</h4>
                <div className="reset-button-wrap">
                  <Button
                    variant="outline"
                    className="dark"
                    onClick={() => {
                      setEmail({ ...email, signature: defaultEmail.signature });
                      setSigSelectedData('');
                      setResetSig(true);
                    }}
                  >
                    {resetIcon} Reset
                  </Button>
                  <Dropdown className="dropdown-select position-static add-content-dropdown" align="end">
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                      <h4 className="program-title text-primary">{addIcon} Add Content</h4>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu} className="dropdown-menu mw-dropdown-default">
                      <Dropdown.Item eventKey="1" onClick={() => setSigSelectedData('{{user_name}}')}>
                        <span title="User Name">User Name</span>
                      </Dropdown.Item>

                      <Dropdown.Item eventKey="2" onClick={() => setSigSelectedData('{{user_email}}')}>
                        <span title="User Email">User Email</span>
                      </Dropdown.Item>

                      <Dropdown.Item eventKey="3" onClick={() => setSigSelectedData('{{faq_link}}')}>
                        <span title="faq_link">FAQ Link</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <Card className="react-text-editor">
                <SchoolEmailEditor
                  theme="snow"
                  name="signature"
                  onChange={(e) => handleChange(e, 'signature')}
                  value={email.signature}
                  dynamicValue={sigSelectedData}
                  resetDynamicValue={() => setSigSelectedData('')}
                  reset={resetSig}
                />
              </Card>
            </div>
            {previewEmail && <EmailPreview show={previewEmail} onClose={() => setPreviewEmail(false)} data={previewData} />}
          </div>
        </>
      ) : (
        <div className="spinner-wrapper">
          <Spinner animation="border" className="centerLoading" size="lg" />
        </div>
      )}
    </>
  );
}

export default SchoolEmails;
