import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
// import HttpBackend from 'i18next-http-backend';
// import resourcesToBackend from 'i18next-resources-to-backend';
import { getInitialTranslations, getTranslationsForModule } from 'api/langApis';
import { availableLanguages } from '../constants/config';
import cachedRequest from './cachedRequest';
import { getLangFromCache } from './helpers';

const langFromCache = getLangFromCache();
const isValidLang = langFromCache && availableLanguages.findIndex((n) => n.value === langFromCache) > -1;
const langValue = isValidLang ? langFromCache : 'en';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(ChainedBackend)
  .init({
    lng: langValue, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    ns: ['default'],
    defaultNS: 'default',
    fallbackNS: 'default',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
      bindI18nStore: 'added',
    },
    // backend: {
    //   backends: [
    //     HttpBackend,
    //     // if a namespace can't be loaded via normal http-backend loadPath, then the inMemoryLocalBackend will try to return the correct resources
    //     resourcesToBackend((language, namespace, callback) => {
    //       import(`../assets/locales/${language}_${namespace}.json`)
    //         .then(({ default: resources }) => {
    //           // with dynamic import, you have to use the "default" key of the module ( https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#importing_defaults )
    //           callback(null, resources);
    //         })
    //         .catch((error) => {
    //           callback(error, null);
    //         });
    //     }),
    //   ],
    //   backendOptions: [
    //     {
    //       // loadPath: `${API_URL}/locales/?lang={{lng}}`,
    //       loadPath: `http://localhost/locales/?lang={{lng}}`,
    //       request: async (options, url, payload, callback) => {
    //         try {
    //           const response = await getInitialTranslations({ url });
    //           callback(null, {
    //             status: response.status,
    //             data: response.data.data,
    //           });
    //         } catch (error) {
    //           callback(error, null);
    //         }
    //       },
    //     },
    //   ],
    // },
  });

export const addModuleTranslations = async (module) => {
  try {
    // const response = await getTranslationsForModule({
    //   lang: i18n.language,
    //   module,
    // });
    const response = await cachedRequest({
      key: 'lang',
      fn: getTranslationsForModule,
      params: [
        {
          lang: i18n.language,
          module,
        },
      ],
      cacheTime: 600,
    });
    i18n.addResources(i18n.language, module, response.data.data);
  } catch (error) {
    console.log(error);
  }
};

export const addTranslationResources = (data, module) => {
  try {
    if (!module) {
      module = i18n.options.defaultNS;
    }
    i18n.addResources(i18n.language, module, data);
  } catch (error) {
    console.log(error);
  }
};

export default i18n;
