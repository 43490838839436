import { Button, Offcanvas } from 'react-myoneui';

function EmailPreview({ show, onClose, data }) {
  const handleClose = () => {
    onClose();
  };
  const offCanvasKeyDown = (e) => e.stopPropagation();

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end" onKeyDown={offCanvasKeyDown} className="w-50">
        <Offcanvas.Header closeButton icon="close" backButton={true} className="pb-0">
          <Offcanvas.Title>Preview School Email</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="email-logs-detail-templates" dangerouslySetInnerHTML={{ __html: data }}></div>
        </Offcanvas.Body>
        <Offcanvas.Footer>
          <Button variant="primary" onClick={handleClose}>
            <div className="d-flex align-items-center gap-2">Close</div>
          </Button>
        </Offcanvas.Footer>
      </Offcanvas>
    </>
  );
}

export default EmailPreview;
