import { Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const RequireAdminAuth = () => {
  const { user } = useSelector((state) => state.user);
  if (!Object.keys(user).length) {
    return <Navigate to="/" replace />;
  }

  return ['super_admin', 'staff', 'school_admin'].includes(user.role.identifier) ? <Outlet /> : <Navigate to="/parent" replace />;
};

const RequireParentAuth = () => {
  const location = useLocation();

  const { user } = useSelector((state) => state.user);

  if (!Object.keys(user).length) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return user.role.identifier === 'parent' ? <Outlet /> : <Navigate to={`/${user.role.identifier}/dashboard`} replace />;
};

const UnProtectedRoute = () => {
  const { user } = useSelector((state) => state.user);

  if (!Object.keys(user).length) {
    return <Outlet />;
  }
  return user.role.identifier === 'parent' ? <Navigate to="/parent" replace /> : <Navigate to={`/${user.role.identifier}/dashboard`} replace />;
};

export { RequireAdminAuth, RequireParentAuth, UnProtectedRoute };
