import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  enableActiveWrapper: false,
  activePage: 'dashboard',
  subMenuStatus: false,
  subMenu: [],
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      for (let x in action.payload) {
        if (x in state) {
          state[x] = action.payload[x];
        }
      }
    },
    setActivePage: (state, action) => {
      state['activePage'] = action.payload;
    },
    setSubMenuStatus: (state, action) => {
      state['subMenuStatus'] = action.payload;
    },
    setSubMenu: (state, action) => {
      state['subMenu'] = action.payload;
    },
    setActiveWrapperStatus: (state, action) => {
      state['enableActiveWrapper'] = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSidebarData, setActivePage, setSubMenuStatus, setSubMenu, setActiveWrapperStatus } = sidebarSlice.actions;

export default sidebarSlice.reducer;
