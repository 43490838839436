import { setUserData } from 'reducers/userSlice';
import mainRoutes from 'routes/mainRoutes';
import { useDispatch } from 'react-redux';
import { ls } from 'utils/ls-crypto';
import storeRoutes from 'routes/storeRoutes';

function App() {
  const dispatch = useDispatch();

  const user = JSON.parse(ls.get('user') || '{}');
  if (user) {
    dispatch(setUserData({ user }));
  }

  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];

  const sub_domain = ['localhost', 'www', 'sandbox', 'uat'].includes(subdomain) ? null : subdomain;

  dispatch(setUserData({ store_sub_domain: sub_domain ?? '' }));
  return sub_domain ? storeRoutes : mainRoutes;
}

export default App;
