const LOCAL_URL = process.env.REACT_APP_LOCAL_URL;
export const API_URL = LOCAL_URL ? LOCAL_URL : window.location.origin;
export const {
  REACT_APP_UPLOADER_URL,
  REACT_APP_BUCKET_NAME,
  REACT_APP_ENV,
  REACT_APP_CURRENT_BASE_URL,
  REACT_APP_CRYPTO_SECRET,
  REACT_APP_SCHOOL_CODE_LINK,
  REACT_APP_HUMM_MAX_LIMIT,
  REACT_APP_HUMM_MIN_LIMIT,
  REACT_APP_EXPORT_ALERT_DISPLAY_TIME,
  REACT_APP_TERMS_OF_SERVICE,
  REACT_APP_PRIVACY_POLICY,
} = process.env;

export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export const availableLanguages = [
  { label: 'EN', value: 'en' },
  { label: 'FR', value: 'fr' },
];

export const reportModuleName = 'admin/reports';
