import Loader from 'components/Loader';
import { RequireParentAuth } from 'components/RequireAuth';
import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
const OrderProfileLayout = lazy(() => import('modules/schoolManagement/dashboard/OrderProfileLayout'));
const Error404 = lazy(() => import('modules/Error404'));
const ProductDetail = lazy(() => import('modules/schoolManagement/productDetail'));
const Bundle = lazy(() => import('modules/schoolManagement/bundle'));
const Billing = lazy(() => import('modules/schoolManagement/billing'));
const SchoolLayout = lazy(() => import('layouts/SchoolLayout'));
const ParentHome = lazy(() => import('modules/schoolManagement/home/parent'));
const PackageList = lazy(() => import('modules/schoolManagement/packageList'));
const PackageItem = lazy(() => import('modules/schoolManagement/packageItem'));
const NewPackage = lazy(() => import('modules/schoolManagement/packageItem/newPackage'));
const OrderDetail = lazy(() => import('modules/schoolManagement/orderDetail'));
const Orders = lazy(() => import('modules/schoolManagement/dashboard'));
const Students = lazy(() => import('modules/schoolManagement/dashboard/students'));
// const Schools = lazy(() => import('modules/schoolManagement/dashboard/schools'));
const Profiles = lazy(() => import('modules/schoolManagement/dashboard/profile'));
const PaymentPlan = lazy(() => import('modules/schoolManagement/dashboard/paymentPlan'));
const ForgotPassword = lazy(() => import('modules/createPassword/forgotPassword'));
const ParentFaq = lazy(() => import('modules/parentFaq'));

export default (
  <Router>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<SchoolLayout />}>
          <Route index element={<PackageList />} />
          <Route path="store/package/:packageId" element={<PackageItem />} />
          <Route path="school/:schoolCode/package-item/:packageId" element={<NewPackage />} />
          <Route path="dashboard" element={<ParentHome />} />
          <Route path="school/:schoolId/bundle" element={<Bundle />} />
          <Route path="school/:schoolId/bundle/:packageId/product-detail" element={<ProductDetail />} />
          <Route path="billing-address" element={<Billing />} />

          <Route element={<RequireParentAuth />}>
            <Route path="profile" element={<Profiles />} />
            <Route path="store/order-details/*" element={<OrderDetail />} />
            <Route element={<OrderProfileLayout />}>
              <Route path="store/order-detail" element={<Orders />} />
              <Route path="store/students" element={<Students />} />
              {/* <Route path="store/schools" element={<Schools />} /> */}
              <Route path="store/installment" element={<PaymentPlan />} />
              <Route path="store/faq" element={<ParentFaq />} />
            </Route>
          </Route>
        </Route>

        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  </Router>
);
